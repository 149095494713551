import {
  React,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Index from "../../Index";
import { useSelector } from "react-redux";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import CustomAreaChartSingle from "../../../component/custom-chart/custom-single-charts/CustomAreaChartSingle";
import CustomDashedChartSingle from "../../../component/custom-chart/custom-single-charts/CustomDashedChartSingle";
import CustomCendalStickChartSingle from "../../../component/custom-chart/custom-single-charts/CustomCendalStickChartSingle";
import useKeyboardShortcut from "../../../component/common/keyboard-shortcuts/useKeyboardShortcut";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Modal from "../../../component/modal/Modal";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import _ from "lodash";
import {
  currentPageMinus,
  currentPagePlus,
  manageChartDrawingData,
  manageDownloadChartData,
  manageDrawingButton,
  manageDrawingChartToken,
  manageDrawingToolActiveDeactive,
  manageEraseDrawing,
  manageExitAllModal,
  manageTaggedStocksCount,
  manageTrackingActiveChartHoverData,
  manageTrackingStocks,
  pageSetting,
  setGridViewStore,
} from "../../../redux/user/action";
import Footer from "../../../component/DefaultLayout/Footer";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import DrawChartButton from "../../../component/modal/DrawChartButton";
import { DataService } from "../../../config/DataService";
import Constants from "../../../component/common/constants";
import OneColumnTrackingWindow from "./OneColumnTrackingWindow";
import OpenOrderList from "./OpenOrderList";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const TrackingWindowCharts = (props) => {
  const dispatch = Index.useDispatch();
  const location = Index.useLocation();
  const [allTagsData, setAllTagsData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const {
    token,
    displaySettings,
    graphType,
    chartDuration,
    chartView,
    categoryFilter,
    isActiveShortCut,
    isUserLogin,
    isExitAllModalOpen,
    pageSetting: pageSettingState,
    gridSpacing,
    gridView,
    otherSettings,
    twoSideArrowZoom,
    isDrawingButtonActive,
    isEraseDrawing,
    taggedStocksCount,
    gridBorder,
    userLoginDetail,
    currentPageRedux,
    sorting,
    isDrawingToolOpen,
    isModalDrawingButtonActive,
    isModalDrawingToolOpen,
    theme,
    usersSettings,
  } = useSelector((state) => state.UserReducer);

  const abortControllers = useRef([]);
  const isMounted = useRef(true);
  const apiHasBeenCalled = useRef(null);
  const [scriptData, setScriptData] = useState([]);
  const [openModel, setOpenModel] = useState({});
  const [currenChart, setCurrenChart] = useState("");
  const [orderCurrentChart, setOrderCurrentChart] = useState("");
  const [updateOrder, setUpdateOrder] = useState({});
  const [placeOrderData, setPlaceOrderData] = useState({});
  const [editOrderData, setEditOrderData] = useState({});
  const [targetOrTopLoss, setTargetOrTopLoss] = useState();
  const [removedStocks, setRemovedStocks] = useState([]);
  const [chartLiveData, setChartLiveData] = useState(null);
  const [cursor, setCursor] = useState(-1);
  const [opacity, setOpacity] = useState(1);
  const [drawingChart, setDrawingChart] = useState(null);
  const [isExitAllOpen, setIsExitAllOpen] = useState(false);
  const [headMenu, setHeadMenu] = useState(false);
  const [tags, setTags] = useState("");
  const [displayDataLength, setDisplayDataLength] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const [openDrawingChartModal, setOpenDrawingChartModal] = useState(false);
  const [openViewChartModal, setOpenViewChartModal] = useState(false);
  const [drawingChartData, setDrawingChartData] = useState(null);
  const miniChartModalRef = useRef(null);
  const [openOrderUpdate, setOpenOrderUpdate] = useState(null);
  const [openOrderExitStockTokens, setOpenOrderExitStockTokens] = useState([]);
  const handleClose = () => {
    setOpenModel({});
  };

  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);

  const themeTitle = localStorage.getItem("default-theme");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth - 10,
    // height: window.innerHeight - 10,
    // bgcolor: "#1E2431",
    bgcolor:
      theme == "Light" ? "#fff" : theme == "Dark" ? "#212121" : "#283142",
    borderRadius: "10px 10px 0 0",
    border: "none",
  };

  const getAllStockTags = async () => {
    const { reset, order, tags, timeFrame } =
      sorting[Constants.sortingPath[location?.pathname]];
    const params = {
      chartView,
      sortDate: reset ? reset : "",
      sort: order ? order : "",
      tags: tags,
      sortInterval: timeFrame ? timeFrame : "",
    };
    try {
      const response = await Index.DataService(userLoginToken).get(
        `${Index.Api.user.getAllTags}?open=true`,
        { params }
      );
      setAllTagsData(response?.data?.data);
      setScriptData(response?.data?.data);
    } catch (error) {}
  };

  useEffect(
    () => {
      getAllStockTags();
    },
    [chartView, sorting[Constants.sortingPath[location?.pathname]]],
    graphType
  );

  useEffect(() => {
    dispatch(manageTrackingStocks(allTagsData));
  }, [allTagsData]);

  const handleUpdateStock = (payload) => {
    const filterCondition = (item, data) => {
      // return JSON.parse(item?.token) == JSON.parse(data?.token) &&
      //   data?.chartView === item?.chartView &&
      //   data?.interval === item?.interval;
      if (data?.chartView == "Positional") {
        return (
          JSON.parse(item?.token) == JSON.parse(data?.token) &&
          item?.chartView === data?.chartView &&
          item?.interval === data?.interval &&
          item?.positionalTimeFrame == data?.positionalTimeFrame
        );
      }
      if (data?.chartView == "Intraday") {
        return (
          JSON.parse(item?.token) == JSON.parse(data?.token) &&
          item?.chartView === data?.chartView &&
          item?.interval === data?.interval
        );
      }
      return false;
    };
    setAllTagsData((prev) =>
      prev?.map((item) => {
        const isMatch = filterCondition(item, payload);
        if (isMatch) {
          return {
            ...item,
            ...payload,
          };
        }
        return item;
      })
    );

    setOpenOrderUpdate(payload)

    if (drawingChartData) {
      setDrawingChartData((prev) => ({ ...prev, ...payload }));
    }
  };

  const handleStopLossTargetHit = (data) => {
    handleUpdateStock(data);
  };

  useEffect(() => {
    if (props?.socket) {
      props?.socket.on(`hit_status_action_${userLoginDetail?._id}`, (data) => {
        if (data) {
          handleStopLossTargetHit(data);
        }
      });

      props?.socket.on(`market_end_${userLoginDetail?._id}`, (data) => {
        getAllStockTags();
        getStockTagsCount();
      });

      props?.socket.on(`tagging_action_${userLoginDetail?._id}`, (data) => {
        setTimeout(() => {
          getAllStockTags();
          getStockTagsCount();
        }, 1000);
      });

      return () => {
        props?.socket.off(`hit_status_action_${userLoginDetail?._id}`);
        props?.socket.off(`market_end_${userLoginDetail?._id}`);
        props?.socket.off(`tagging_action_${userLoginDetail?._id}`);
      };
    }
  }, [props?.socket]);

  // useEffect(() => {
  //   if (props.socket && drawingChartData) {
  //       props.socket.on(`token_${JSON.parse(drawingChartData?.token)}`, (data) => {
  //         setChartLiveData({...data, token: JSON.parse(data?.token)});
  //       });

  //       return () =>{
  //         if(chartView === "Positional") {
  //         props?.socket?.off(`token_${JSON.parse(drawingChartData?.token)}`)
  //       }
  //     }
  //   }
  // }, [props.socket, drawingChartData]);

  const handleCategoryWiseFilter = useCallback(
    (ele) => {
      if (ele) {
        const exchangeType = ele?.exchange_type?.toString();
        return categoryFilter?.length
          ? categoryFilter?.includes(exchangeType)
          : ele;
      }
    },
    [categoryFilter]
  );

  const rows = useMemo(() => {
    return (
      allTagsData?.length &&
      allTagsData
        ?.filter(handleCategoryWiseFilter)
        ?.map((item, index, array) => {
          return {
            item,
            tag: item?.tag || "",
            entryPrice: item?.entryPrice || 0,
            token: item?.token,
            stockData: item?.stockData || [],
            timeStamp: item?.timeStamp,
            topLossAt: item?.topLossAt,
            targetAt: item?.targetAt,
            targetStatus: item?.targetStatus,
            stopLossStatus: item?.stopLossStatus,
            lastTradedPrice: item?.lastTradedPrice,
            exchange_type: item?.exchange_type,
          };
        })
    );
  }, [allTagsData, categoryFilter]);

  const removeTopLossAtOrTargetAt = async (token, status) => {
    try {
      const response = await Index.DataService(userLoginToken).post(
        "/user/stocks/remove-toploss-or-target",
        {
          token: JSON.parse(token),
          targetTag: status,
          chartView,
          interval: chartDuration[chartView]?.interval,
        }
      );
      // Index.toast.success("status updated");
      setTargetOrTopLoss();
      setEditOrderData({});
    } catch (error) {
      setTargetOrTopLoss();
      setEditOrderData({});
    }
  };

  function deleteLine() {
    // get the selected annotation
    if (orderCurrentChart) {
      let controller = orderCurrentChart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();
      let marker = orderCurrentChart.plot(0).textMarker(selectedAnnotation.id);
      marker.remove();
      controller.removeAnnotation(selectedAnnotation);
      if (targetOrTopLoss) {
        setTimeout(() => {
          removeTopLossAtOrTargetAt(editOrderData?.token, targetOrTopLoss);
          let payload = {
              token : editOrderData?.token,
              chartView: editOrderData?.chartView,
              interval: editOrderData?.interval,
              positionalTimeFrame: editOrderData?.positionalTimeFrame,
              [targetOrTopLoss]: null
          }
          setOpenOrderUpdate(payload)
        }, 1000);
      }
    }
  }

  //Shortcut Alt + t to set target
  const handleSetTarget = () => {
    const activeChart = document.querySelector(".active-chart");
    if (!activeChart) return;

    const token = activeChart.getAttribute("data");
    const chartData = allTagsData?.find(
      (item) => JSON.parse(item?.token) === JSON.parse(token)
    );

    if (orderCurrentChart && chartData) {
      const plot = orderCurrentChart.plot(0);
      const controller = plot.annotations();
      const annotations = controller
        .toJson()
        .annotationsList.filter((ele) => ele.type !== "marker");

      if (annotations.length < 3) {
        const currentPrice = chartData.livePrice[5];
        const price =
          chartData.tag === "Buy" ? currentPrice - 5 : currentPrice + 5;

        const line = controller
          .startDrawing("horizontal-line")
          .color("#00FF00");
        line.id = controller.getAnnotationsCount() - 1;

        plot
          .textMarker(line.id)
          .enabled(true)
          .text(price)
          .value(price)
          .align("right")
          .padding(3, 5)
          .anchor("left-center")
          .fontColor("#FFFFFF")
          .fontSize(10)
          .background("#00a12b")
          .zIndex(999);

        line.valueAnchor(price);

        const position = chartData.tag === "Buy" ? "arrow-up" : "arrow-down";

        setEditOrderData({
          targetAt: {
            targetTag: "targetAt",
            price: price,
            position,
            color: otherSettings?.[theme]?.["target"]?.color,
            token: JSON.parse(token),
          },
          token: JSON.parse(token),
        });
        setOpenModel({ [JSON.parse(chartData?.token)]: true });
      }

      // let anno = controller.getAnnotationAt(2)

      // controller.select(anno)
    }
  };

  //Shortcut Alt + l to set stop loss
  const handleSetStopLoss = () => {
    const activeChart = document.querySelector(".active-chart");
    if (!activeChart) return;

    const token = activeChart.getAttribute("data");
    const chartData = allTagsData?.find(
      (item) => JSON.parse(item?.token) === JSON.parse(token)
    );

    if (orderCurrentChart && chartData) {
      const plot = orderCurrentChart.plot(0);
      const controller = plot.annotations();
      const annotations = controller
        .toJson()
        .annotationsList.filter((ele) => ele.type !== "marker");

      if (annotations.length < 3) {
        const currentPrice = chartData.livePrice[5];
        const price =
          chartData.tag === "Buy" ? currentPrice + 5 : currentPrice - 5;

        let line = controller.startDrawing("horizontal-line").color("#FF0000");

        line.id = plot.annotations().getAnnotationsCount() - 1;

        plot
          .textMarker(line.id)
          .enabled(true)
          .text(price)
          .value(price)
          .align("right")
          .padding(3, 5)
          .anchor("left-center")
          .fontColor("#FFFFFF")
          .fontSize(10)
          .background("#FF0000")
          .zIndex(999);

        line.valueAnchor(price);

        const position = chartData.tag === "Buy" ? "arrow-down" : "arrow-up";

        setEditOrderData({
          topLossAt: {
            targetTag: "topLossAt",
            price: price,
            position,
            color: otherSettings?.[theme]?.["stopLoss"]?.color,
            token: JSON.parse(token),
          },
          token: JSON.parse(token),
        });
        setOpenModel({ [JSON.parse(chartData?.token)]: true });
      }
    }
  };

  function handleActiveCursorAction() {
    setCursor(-1);
  }

  const fromDate = usePreviousDateCalculator(
    chartView === "Intraday" ? 1 : chartDuration[chartView]?.timeFrame - 1
  );

  const removeStockTag = async (t, payload, currentPrice) => {
    try {
      let data = {
        token: JSON.parse(payload?.token),
        tag: t,
        chartView,
        interval: payload?.interval,
      };
      if (chartView === "Positional") {
        data["timeFrame"] = payload?.timeFrame;
      }
      if (currentPrice) {
        data["lastTradedPrice"] = currentPrice;
      }
      await Index.DataService(userLoginToken).post(
        Index.Api.user.removeStock,
        data
      );
      setOpenOrderUpdate({...payload, positionalTimeFrame: payload?.timeFrame, removeTag:true})
      setOpenDrawingChartModal(false);
      if (props?.socket) {
        props?.socket.emit("stock_action", "Action taken");
      }

      if (props?.socket) {
        props?.socket.emit("tagging_action", userLoginDetail?._id);
      }
      const annotationData = {
        token: JSON.parse(payload?.token),
        data: [],
        chartType: chartView,
        interval: Constants.annotationTimeFrames[payload?.interval],
      };
      handleUpdateAnnotation(annotationData);
      setAllTagsData((prev) => {
        const showDataLength = prev
          ?.filter(
            (item) => JSON.parse(item?.token) !== JSON.parse(payload?.token)
          )
          ?.slice(
            pageSettingState?.page * pageSettingState?.rowsPerPage -
              pageSettingState?.rowsPerPage,
            pageSettingState?.page * pageSettingState?.rowsPerPage
          );

        if (showDataLength?.length == 0 && currentPageRedux > 1) {
          dispatch(
            pageSetting({
              ...pageSettingState,
              page: currentPageRedux - 1,
            })
          );
        }
        // return prev?.filter(
        //   (item) => JSON.parse(item?.token) !== JSON.parse(token)
        // );
        return prev?.filter((item) => {
          if (payload?.chartView == "Positional") {
            return !(
              JSON.parse(item?.token) == JSON.parse(payload?.token) &&
              item?.chartView === payload?.chartView &&
              item?.interval === payload?.interval &&
              item?.positionalTimeFrame == payload?.timeFrame
            );
          }
          if (payload?.chartView == "Intraday") {
            return !(
              JSON.parse(item?.token) == JSON.parse(payload?.token) &&
              item?.chartView === payload?.chartView &&
              item?.interval === payload?.interval
            );
          }
          return true;
        });
      });

      getStockTagsCount();
    } catch (error) {
      // toast.success("Tag updated");
    }
  };

  const getStockTagsCount = async () => {
    try {
      let params = {
        chartView,
      };
      const response = await Index.DataService(userLoginToken).get(
        Index.Api.user.stockTagCounts,
        { params }
      );
      dispatch(manageTaggedStocksCount(response.data.data));
    } catch (error) {}
  };

  useEffect(() => {
    getStockTagsCount();
  }, [chartView]);

  const handleDisplayDataLength = () => {
    const data = gridView?.row * gridView?.column;
    setDisplayDataLength(data);
  };

  useEffect(() => {
    handleDisplayDataLength();
  }, [gridView]);

  useEffect(() => {
    setPageData([]);
    const indexOfLastRow =
      pageSettingState?.page * pageSettingState?.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - pageSettingState?.rowsPerPage;
    const modifiedRows = allTagsData?.slice(indexOfFirstRow, indexOfLastRow);
    setPageData(modifiedRows);
    // setCursor(-1);
  }, [pageSettingState, allTagsData, chartView]);

  //Four arrow functionality
  const handleKeyDown = (e) => {
    setPageData((el) => {
      if (e.keyCode === 38) {
        setCursor((e) => (e >= gridView?.row ? e - gridView?.row : e));
      } else if (e.keyCode === 40) {
        setCursor((cursor) =>
          cursor < 0
            ? 0
            : cursor < el.length - 1 && el?.length > cursor + gridView?.row
            ? cursor + gridView?.row
            : cursor
        );
      } else if (e.keyCode === 37) {
        if (cursor >= 0) {
          setCursor((e) => (e - 1 === -1 ? e : e - 1));
        } else {
          // dispatch(currentPageMinus());
        }
      } else if (e.keyCode === 39) {
        if (cursor >= 0) {
          setCursor((cursor) =>
            cursor < el.length - 1 && cursor !== null ? cursor + 1 : cursor
          );
        } else {
          // dispatch(currentPagePlus());
        }
      }

      return el;
    });
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isActiveShortCut, isUserLogin, gridView, cursor, pageSettingState?.page]);

  //Get Annotation
  const getAllAnnotations = useCallback(async () => {
    try {
      const response = await DataService(token).get(
        Index.Api.user.getAnnotations,
        {
          params: {
            chartType: chartView,
            isDashboard: false,
          },
        }
      );

      dispatch(manageChartDrawingData(response?.data?.data));
    } catch (error) {}
  }, [chartView]);

  useEffect(() => {
    getAllAnnotations();
  }, [chartView]);

  //Update Annotation
  const handleUpdateAnnotation = async (data) => {
    try {
      const response = await DataService(token, "application/json").post(
        Index.Api.user.addUpdateAnnotation,
        { ...data, isDashboardAnnotations: false }
      );
      getAllAnnotations();
    } catch (error) {}
  };

  function removeAnnotationOnDeletePress() {
    // get the selected annotation
    if (drawingChart?.chart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();
      if (selectedAnnotation) {
        const updatedAnnotations = controller
          .removeAnnotation(selectedAnnotation)
          .toJson()?.annotationsList;

        let annotationData = {
          token: JSON.parse(drawingChart?.token),
          data: updatedAnnotations,
          chartType: chartView,
          interval:
            Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
        };

        handleUpdateAnnotation(annotationData);
      }
    }
  }

  const previousDate = usePreviousDateCalculator(1);

  const getChartData = async (rowData, setDrawingChartData) => {
    let live = chartView === "Intraday";
    const intervalData = Constants.chartInterval[rowData?.interval];
    const minutesToLess = intervalData?.value * 60000;

    const formData = {
      tokens: [{ token: rowData?.token, type: rowData?.exchange_type }],
      interval: intervalData?.interval,
      fromDate,
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset: intervalData?.offset,
    };
    const formDataIntraday = {
      tokens: [{ token: rowData?.token, type: rowData?.exchange_type }],
      interval: intervalData?.interval,
      fromDate: Index.moment().format("YYYY-MM-DD"),
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset: intervalData?.offset,
      previousDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
          ]
        : [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
            Index.DataService(token).post(
              Index.Api.user.getAllHistoricalData,
              formData
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;
      if (previousDateData && previousDateData[rowData?.token]) {
        setDrawingChartData((prev) => ({
          ...prev,
          previousClosePrice: previousDateData[rowData?.token],
        }));
      }

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        [key]: combinedData[key],
      }));

      if (
        formattedCombinedData.length &&
        formattedCombinedData[0]?.[rowData?.token] &&
        formattedCombinedData[0][rowData?.token]?.length
      ) {
        const format = formattedCombinedData[0][rowData?.token].map((el) => {
          const timestamp =
            Index.moment(el[0]).startOf("minute").valueOf() - minutesToLess;
          return [timestamp, +el[1], +el[2], +el[3], +el[4], +el[4]];
        });
        setDrawingChartData((prev) => ({ ...prev, stockData: format }));
      }
    } catch (error) {}
    setOpenDrawingChartModal(true);
  };

  const handleDrawingChartModal = (data) => {
    if (cursor >= 0 || data?.token) {
      if (data?.token) {
        setOpenViewChartModal(true);
      }
      const chartList = rows?.slice(
        pageSettingState?.page * pageSettingState?.rowsPerPage -
          pageSettingState?.rowsPerPage,
        pageSettingState?.page * pageSettingState?.rowsPerPage
      );

      const rowData = data?.token
        ? chartList?.find((item) => item?.token == data?.token)?.item ?? null
        : chartList?.[cursor]?.item ?? null;

      if (rowData) {
        setDrawingChartData({
          ...rowData,
          tag: rowData?.tag ?? "",
        });
        getChartData(rowData, setDrawingChartData);
        dispatch(manageDrawingChartToken(rowData?.token));
      }
    }
  };

  const handleDrawingChartModalClose = () => {
    setOpenDrawingChartModal(false);
    setOpenViewChartModal(false);
    setDrawingChartData(null);
    if (isModalDrawingButtonActive) {
      dispatch(manageDrawingButton({ type: "Modal" }));
    }

    if (isModalDrawingToolOpen) {
      dispatch(
        manageDrawingToolActiveDeactive({ type: "Modal", status: false })
      );
    }
  };

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    headerRemoveTargetOrStopLoss: deleteLine,
    handleEscapeModalOff: handleActiveCursorAction,
    handleSetTarget,
    handleSetStopLoss,
    removeAnnotationOnDeletePress,
    handleDrawingChartModal,
  });

  //Erase single chart drawing
  function handleEraseDrawing() {
    if (drawingChart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let clearAnnotation = controller.removeAllAnnotations();
      let dataToRemove = clearAnnotation.toJson()?.annotationsList;
      let annotationData = {
        token: JSON.parse(drawingChart?.token),
        data: dataToRemove,
        chartType: chartView,
        interval:
          Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
      };
      handleUpdateAnnotation(annotationData);
      dispatch(manageEraseDrawing(false));
    }
  }

  useEffect(() => {
    if (isEraseDrawing) {
      handleEraseDrawing();
    }
  }, [isEraseDrawing]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        dispatch(setGridViewStore({ column: 2, row: 1 }));
      } else {
        if (usersSettings?.gridView) {
          dispatch(setGridViewStore(usersSettings?.gridView));
        }
      }
    };

    if (window.innerWidth < 992) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(
    () => {
      let mainGridHeight = document.getElementsByClassName(
        "dashboard-chart-main"
      )?.[0]?.clientHeight;

      let chartHeaderHeight =
        document.getElementsByClassName("order-header-main")?.[0]?.clientHeight;

      setChartHeight(
        (mainGridHeight -
          (chartHeaderHeight ?? 0) * gridView?.column -
          (gridSpacing * 8 * (gridView?.column - 1) + 8) -
          0) /
          gridView?.column
      );
    },
    [document.getElementsByClassName("order-header-main")?.[0]?.clientHeight],
    gridView
  );

  return (
    <>
      <Index.Box className="dashboard-main tracking-window-chart tracking-window">
        {isDrawingButtonActive && (
          <DrawChartButton
            newChart={drawingChart && drawingChart?.chart}
            token={drawingChart && drawingChart?.token}
            setNewChart={setDrawingChart}
            interval={
              drawingChart &&
              Constants.annotationTimeFrames[drawingChart?.interval]
            }
            isDrawingToolOpen={isDrawingToolOpen}
          />
        )}
        <Index.Box className="dashboard-wrapper">
          <Index.Overlay></Index.Overlay>
          <Index.Box
            className={`main-content-sec dashboard-main-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            <Index.Box className="tracking-chart-sub-header">
              <Index.Box className="tracking-sub-btn-main">
                <Index.PrimaryButton
                  className="primary-btn header-btn"
                  btnLabel="Exit All"
                  onClick={() => dispatch(manageExitAllModal())}
                />
              </Index.Box>
              <Index.Box className="trade-btn-flex">
                <Index.Box className="trade-btn bg-green">
                  {taggedStocksCount?.buyTag || 0}
                </Index.Box>
                <Index.Box className="trade-btn bg-red">
                  {taggedStocksCount?.sellTag || 0}
                </Index.Box>
                <Index.Box className="trade-btn bg-blue">
                  {taggedStocksCount?.watchTag || 0}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {rows?.length ? (
              <>
                {chartView === "Intraday" || chartView === "Positional" ? (
                  <Index.Grid
                    container
                    spacing={gridSpacing}
                    className={`chart-main dashboard-chart-main tracking-dashboard-chart-main dashboard-chart-mobile ${
                      twoSideArrowZoom && "tracking-chart-main-zoom"
                    } `}
                  >
                    {rows?.length
                      ? rows
                          ?.slice(
                            pageSettingState?.page *
                              pageSettingState?.rowsPerPage -
                              pageSettingState?.rowsPerPage,
                            pageSettingState?.page *
                              pageSettingState?.rowsPerPage
                          )
                          ?.map((item, index, array) => {
                            return (
                              // <Index.Box key={item?.token}>
                              <OneColumnTrackingWindow
                                key={item?.item?._id}
                                exchange_type={item?.exchange_type}
                                index={index}
                                item={item}
                                token={JSON.parse(item?.token)}
                                length={pageSettingState?.rowsPerPage}
                                active={index === cursor}
                                selectedId={index === cursor ? index : null}
                                setOpenModel={setOpenModel}
                                openModel={openModel}
                                deleteLine={deleteLine}
                                handleClose={handleClose}
                                updateOrder={updateOrder}
                                setUpdateOrder={setUpdateOrder}
                                editOrderData={editOrderData}
                                setEditOrderData={setEditOrderData}
                                removeStockTag={removeStockTag}
                                placeOrderData={placeOrderData}
                                setPlaceOrderData={setPlaceOrderData}
                                currenChart={currenChart}
                                setCurrenChart={setCurrenChart}
                                setTargetOrTopLoss={setTargetOrTopLoss}
                                orderCurrentChart={orderCurrentChart}
                                setOrderCurrentChart={setOrderCurrentChart}
                                setDrawingChart={setDrawingChart}
                                setAllTagsData={setAllTagsData}
                                scriptData={scriptData}
                                setScriptData={setScriptData}
                                fromDate={fromDate}
                                live={chartView === "Intraday"}
                                socket={props.socket}
                                handleUpdateStock={handleUpdateStock}
                                handleUpdateAnnotation={handleUpdateAnnotation}
                                openDrawingChartModal={openDrawingChartModal}
                                setOpenOrderUpdate={setOpenOrderUpdate}
                                handleDrawingChartModal={
                                  handleDrawingChartModal
                                }
                              />
                              // </Index.Box>
                            );
                          })
                      : ""}
                    {rows?.length
                      ? Array.from(
                          {
                            length:
                              pageSettingState?.rowsPerPage -
                              rows?.slice(
                                pageSettingState?.page *
                                  pageSettingState?.rowsPerPage -
                                  pageSettingState?.rowsPerPage,
                                pageSettingState?.page *
                                  pageSettingState?.rowsPerPage
                              )?.length,
                          },
                          (_, index) => (
                            <Index.Grid item xs={gridView?.column} key={index}>
                              <Index.Box sx={{ height: chartHeight }} />
                            </Index.Grid>
                          )
                        )
                      : ""}
                  </Index.Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Index.Box className="loader-box">No record found</Index.Box>
            )}
            <OpenOrderList
              socket={props?.socket}
              scriptData={scriptData}
              handleUpdateStock={handleUpdateStock}
              getStockTagsCount={getStockTagsCount}
              openOrderUpdate={openOrderUpdate}
              setOpenOrderUpdate={setOpenOrderUpdate}
              openOrderExitStockTokens={openOrderExitStockTokens}
              setOpenOrderExitStockTokens={setOpenOrderExitStockTokens}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Footer
        pageData={rows}
        apiHasBeenCalled={apiHasBeenCalled}
        cursor={cursor}
        className="tracking-win-footer"
      />

      {openDrawingChartModal && (
        <>
          {" "}
          <Index.Box>
            <Index.Modal
              open={openDrawingChartModal}
              onClose={() => {
                handleDrawingChartModalClose();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              ref={miniChartModalRef}
            >
              <Index.Box
                sx={
                  openViewChartModal
                    ? { ...style, width: 1000 }
                    : { ...style, height: window.innerHeight - 10 }
                }
                className="mini-chart-modal-box"
              >
                <Index.Box className="modal-header-sec custom-modal-header">
                  <Index.OrderChartHeader
                    live={chartView === "Intraday"}
                    entryPrice={drawingChartData?.entryPrice || "-"}
                    defaultTag={drawingChartData?.tag || ""}
                    token={drawingChartData?.token}
                    stockData={drawingChartData?.stockData}
                    isStopLossHit={
                      drawingChartData?.topLossAt?.completed
                        ? "Stop loss"
                        : drawingChartData?.targetAt?.completed
                        ? "Target"
                        : null
                    }
                    headerData={drawingChartData}
                    stockTag={drawingChartData?.tag}
                    setOpenModel={setOpenModel}
                    openModel={openModel}
                    setScriptData={setScriptData}
                    scriptData={scriptData}
                    setAllTagsData={setAllTagsData}
                    removeStockTag={removeStockTag}
                    socket={props?.socket}
                    // livePrice={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                    handleClose={() => {
                      handleDrawingChartModalClose();
                    }}
                    miniChartModalRef={miniChartModalRef}
                    isDrawingChart={true}
                    drawingChart={drawingChart}
                    setDrawingChart={setDrawingChart}
                    drawingChartData={drawingChartData}
                    setDrawingChartData={setDrawingChartData}
                    handleUpdateStock={handleUpdateStock}
                    setOpenOrderUpdate={setOpenOrderUpdate}
                  />
                </Index.Box>
                <Index.Box className="modal-body-sec">
                  {graphType[theme][chartView] === 0 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomLineChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 1 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomAreaChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 2 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomDashedChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 3 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomCendalStickChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                      />
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>{" "}
        </>
      )}
      <Modal
        show={isExitAllModalOpen}
        setShow={() => dispatch(manageExitAllModal())}
        title={"Exit From Stock"}
        x={window?.innerWidth / 2 - 180}
        y={window?.innerHeight / 2 - 130}
        opacity={opacity}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        setOpacity={setOpacity}
        width={"407px"}
        html={
          <ExitStockModal
            setShowExitModal={() => dispatch(manageExitAllModal())}
            getAllTagsData={getAllStockTags}
            setRemovedStocks={setAllTagsData}
            allTagsData={allTagsData}
            getStockTagsCount={getStockTagsCount}
            socket={props?.socket}
            setOpenOrderExitStockTokens={setOpenOrderExitStockTokens}
          />
        }
      />
    </>
  );
};

export default memo(TrackingWindowCharts);

const ExitStockModal = ({
  setShowExitModal,
  setRemovedStocks,
  allTagsData,
  getStockTagsCount,
  socket,
  setOpenOrderExitStockTokens
}) => {
  const { token, chartView, chartDuration } = useSelector(
    (state) => state.UserReducer
  );
  const allStockTypes = [
    { id: 1, name: "Equity", value: "NSE" },
    { id: 2, name: "F&O", value: "NFO" },
    { id: 3, name: "Commodity", value: "MCX" },
  ];

  //Validation
  const validationSchemaExitStock = yup.object().shape({
    category: yup.string().required("Please select category"),
  });

  const handleExitFromStock = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("exchange_type", values?.category);
    urlencoded.append("chartView", chartView);
    Index.DataService(token)
      .post(Index.Api.user.exitStocks, urlencoded)
      .then((data) => {
        if (data?.data?.status === 200) {
          let tokensList = allTagsData?.filter((ele) => ele?.exchange_type == values?.category
          )?.map((item)=> JSON.parse(item?.token));
          setOpenOrderExitStockTokens(tokensList)
          setRemovedStocks((prev) =>
            prev?.filter(
              (item) => !tokensList?.includes(JSON.parse(item?.token))
            )
          );
          if (socket) {
            socket.emit("stock_action", "Action taken");
          }
          getStockTagsCount();
        }
      })
      .catch((error) => {
        // setShowExitModal(false);
      });
    setShowExitModal(false);
  };
  return (
    <>
      <Index.Box className="body-p sorting-body-wrapper">
        <Index.Box className="content-wrapper">
          <Formik
            validationSchema={validationSchemaExitStock}
            initialValues={{ category: "" }}
            onSubmit={handleExitFromStock}
          >
            {({ values, errors, handleBlur, handleChange, touched }) => (
              <Form>
                <Index.Box className="name-wrap name-warp-tracking-window">
                  <Index.Box className="cus-input-grp exit-stock-modal-box">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Category
                    </Index.Typography>
                    <Index.Box className="form-group">
                      <Index.FormControl className="form-control-user drop-form-control">
                        <Index.Select
                          fullWidth
                          name="category"
                          className="form-control"
                          displayEmpty
                          value={values?.category}
                          placeholder="User Type"
                          // onChange={handleChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {values?.category === "" ||
                          values?.category === undefined ? (
                            <Index.MenuItem
                              disabled
                              value=""
                              className="form-control exit-catg-option"
                            >
                              Category
                            </Index.MenuItem>
                          ) : null}
                          {allStockTypes?.map((ele) => {
                            return (
                              <Index.MenuItem
                                key={ele?.id}
                                value={ele?.value}
                                // name="admin"
                                className="drop-menuitem game-item"
                              >
                                {ele?.name}
                              </Index.MenuItem>
                            );
                          })}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormHelperText error className="error-text">
                        {errors.category && touched.category
                          ? errors.category
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.PrimaryButton
                    className="primary-btn save-changes-btn tracking-window"
                    btnLabel="Exit"
                    type="submit"
                  />
                </Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};
