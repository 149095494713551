import React, { useCallback, useEffect, useState } from "react";
import Draggable from "react-draggable";
import Index from "../Index";
import { Slider } from "@mui/material";
import {
  manageChartDrawingData,
  manageDrawingToolSetting,
  manageDrawingToolsList,
  manageToolOrientation,
  manageUserSetting,
} from "../../redux/user/action";
import { DataService } from "../../config/DataService";
import Constants from "../common/constants";

const DrawingToolBox = ({
  title,
  show,
  x,
  y,
  setShow,
  html,
  width,
  height,
  opacity,
  setOpacity,
  SVG,
  newChart,
  token,
  setNewChart,
  interval,
}) => {
  const dispatch = Index.useDispatch();
  const location = Index.useLocation();
  const {
    chartView,
    token: userToken,
    drawingToolsList,
    toolOrientation,
    chartDuration,
    theme,
    drawingToolSetting,
  } = Index.useSelector((state) => state.UserReducer);
  const themeTitle = localStorage.getItem("default-theme");

  const [isActive, SetIsActive] = useState(false);
  const [isSliderActive, setIsSliderActive] = useState(true);
  const [strokeColor, setStrokeColor] = useState(drawingToolSetting?.strokeColor || "#e06666");
  const [fillColor, setFillColor] = useState(drawingToolSetting?.fillColor || "#e06666");
  const [strokeThickness, setStrokeThickness] = useState(drawingToolSetting?.thickness || 1);
  const [selectedAnnotationType, setSelectedAnnotationType] = useState("");

  const handleClose = () => {
    SetIsActive(true);
    setShow(!show);
  };

  //Add annotation
  const addAnnotation = (annotationType) => {
    if (newChart) {
      setSelectedAnnotationType(annotationType);
      let unselectController = newChart.plot(0).annotations();
      let selectedAnnotation = unselectController.toJson();
      if (selectedAnnotation) {
        unselectController.unselect(selectedAnnotation);
        // setStrokeColor("#e06666");
        // setFillColor("#e06666");
        // setStrokeThickness(1);
      }

      const controller = newChart.plot(0).annotations();
      controller.startDrawing({
        type: annotationType,
        color: "transparent",
        stroke: {
          color: strokeColor || "#e06666",
          opacity: 0.7,
          thickness: strokeThickness,
        },
      });
    }
  };

  const handleUpdateAnnotation = async (data) => {
    try {
      const response = await DataService(userToken, "application/json").post(
        Index.Api.user.addUpdateAnnotation,
        {
          ...data,
          isDashboardAnnotations: location?.pathname === "/user/dashboard",
        }
      );
      getAllAnnotations();
    } catch (error) {}
  };

  const getAllAnnotations = async () => {
    try {
      const response = await DataService(userToken).get(
        Index.Api.user.getAnnotations,
        {
          params: {
            chartType: chartView,
            isDashboard: location?.pathname === "/user/dashboard",
          },
        }
      );

      dispatch(manageChartDrawingData(response?.data?.data));
    } catch (error) {}
  };

  //Remove selected annotation
  const removeSelectedAnnotation = async () => {
    if (newChart) {
      const controller = newChart.plot(0).annotations();
      const selectedAnnotation = controller.getSelectedAnnotation();

      if (selectedAnnotation) {
        const updatedAnnotations = controller
          .removeAnnotation(selectedAnnotation)
          .toJson()?.annotationsList;

        let annotationData = {
          token: JSON.parse(token),
          data: updatedAnnotations,
          chartType: chartView,
          interval,
        };

        handleUpdateAnnotation(annotationData);
        // setStrokeColor("#e06666");
        // setStrokeThickness(1);
        // setFillColor("#e06666");
        setSelectedAnnotationType("");
      }
    }
  };

  //Fill annotation background color
  const handleFillColorChange = (e) => {
    const colorHash = e.target.value;
    setFillColor(colorHash);
    if (newChart) {
      const controller = newChart.plot(0).annotations();
      const selectedAnnotation = controller.getSelectedAnnotation();

      if (selectedAnnotation) {
        selectedAnnotation.fill && selectedAnnotation.fill(e.target.value);
        selectedAnnotation.color && selectedAnnotation.color(e.target.value);
        let myData = controller?.toJson();
        let dataToStore = myData?.annotationsList;

        if (token) {
          let annotationData = {
            token: JSON.parse(token),
            data: dataToStore,
            chartType: chartView,
            interval,
          };
          handleUpdateAnnotation(annotationData);
        }
      }
    }
  };

  //Stroke thickness annotation
  const handleStrokeThicknessChange = (e) => {
    const thickness = e?.target?.value;
    setStrokeThickness(thickness);
    dispatch(manageDrawingToolSetting({thickness}))
    dispatch(manageUserSetting(userToken, "drawingToolSetting.thickness", thickness))
    if (newChart) {
      const controller = newChart.plot(0).annotations();
      const selectedAnnotation = controller.getSelectedAnnotation();

      if (selectedAnnotation) {
        selectedAnnotation.stroke &&
          selectedAnnotation.stroke({
            color: strokeColor,
            thickness: e.target.value,
          });
        let myData = controller?.toJson();
        let dataToStore = myData?.annotationsList;

        if (token) {
          let annotationData = {
            token: JSON.parse(token),
            data: dataToStore,
            chartType: chartView,
            interval,
          };
          handleUpdateAnnotation(annotationData);
        }
      }
    }
  };

  //Fill annotation stroke color
  const handleStrokeColorChange = (e) => {
    const strokeColor = e.target.value;
    setStrokeColor(strokeColor);
    dispatch(manageDrawingToolSetting({strokeColor}));
    dispatch(manageUserSetting(userToken, "drawingToolSetting.strokeColor", strokeColor))

    if (newChart) {
      const controller = newChart.plot(0).annotations();
      const selectedAnnotation = controller.getSelectedAnnotation();

      if (selectedAnnotation) {
        selectedAnnotation.stroke &&
          selectedAnnotation.stroke({
            color: e.target.value,
            thickness: strokeThickness,
          });
        let myData = controller?.toJson();
        let dataToStore = myData?.annotationsList;

        if (token) {
          let annotationData = {
            token: JSON.parse(token),
            data: dataToStore,
            chartType: chartView,
            interval,
          };
          handleUpdateAnnotation(annotationData);
        }
      }
    }
  };

  //Update Tools
  const handleUpdateTools = async (toolId, isActive) => {
    let data = {
      toolId,
      isActive,
    };
    const updateData = drawingToolsList?.map((item) => {
      if (item?.id === toolId) {
        return { ...item, isActive };
      }
      return item;
    });
    dispatch(manageDrawingToolsList(updateData));
    try {
      const response = await DataService(userToken).post(
        Index.Api.user.updateTool,
        data
      );
    } catch (error) {}
  };

  const getAllToolsList = useCallback(async () => {
    try {
      const response = await DataService(userToken).get(
        Index.Api.user.getAllTools
      );
      dispatch(manageDrawingToolsList(response?.data?.data?.data));
    } catch (error) {}
  }, []);

  useEffect(() => {
    getAllToolsList();
  }, []);

  useEffect(() => {
    if (newChart) {
      newChart.listen("annotationSelect", function (event) {
        const selectedAnnotation = event.annotation;
        if (selectedAnnotation) {
          let fillColor = selectedAnnotation?.fill && selectedAnnotation.fill();
          let strokeColor =
            selectedAnnotation?.stroke && selectedAnnotation.stroke();

          setFillColor(fillColor);
          setStrokeColor(strokeColor?.color);
          setStrokeThickness(strokeColor?.thickness);
          setSelectedAnnotationType(
            selectedAnnotation?.getType()?.split("-")?.join("")
          );
        }
      });

      newChart.listen("annotationUnselect", function (event) {
        // setStrokeColor("#e06666");
        // setStrokeThickness(1);
        // setFillColor("#e06666");
        setSelectedAnnotationType("");
      });
    }
  }, [newChart]);
  
  const [isActive1, setActive1] = useState(false);

  const toggleClass = () => {
    setActive1(!isActive1);
    setNewChart(null);
  };

  const [isActiveToggle, setActiveToggle] = useState(false);
  const orientationClass = () => {
    setActiveToggle(!isActiveToggle);
  };
  useEffect(() => {
    if (window.innerWidth < 500) {
      dispatch(manageToolOrientation(false));
    }
  }, [window]);
  return (
    <>
      <Index.Box
        className="handle drawing-tool-main"
        style={{ opacity: opacity + 0.2 || 1 }}
      >
        <Index.Box
          className={
            toolOrientation
              ? "orientation-option horizon"
              : "orientation-option"
          }
        >
          <Index.Box className="modal-wrapper drawing-modal-wrapper">
            <Index.Box className="modal-header cus-between">
              <Index.Box className="model-header-right cus-vertical-center">
                <Index.Box className="btn-bg opacity-btn test">
                  <p>Opacity</p>
                  <Index.Box sx={{ width: 55 }}>
                    <Slider
                      aria-label="Temperature"
                      color="secondary"
                      step={25}
                      value={opacity * 100 + 20 || 100}
                      onChange={(e) => {
                        setOpacity(e.target.value / 100);
                      }}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  className="model-header-left cus-vertical-center"
                  sx={{ marginLeft: "20px" }}
                  onClick={toggleClass}
                >
                  <img
                    className="setting-ic cursor-pointer"
                    alt="settingsolid"
                    width={20}
                    src={
                      theme === "Medium"
                        ? Index.Svg.settingsSolid
                        : theme === "Light"
                        ? Index.Svg.settingsLight
                        : Index.Svg.settingsDark
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="modal-body modal-body-toolbar">
              <Index.Box
                className={isActive1 ? "showed-option show" : "showed-option "}
              >
                <Index.Box className="button-wraps">
                  <Index.Box
                    className="toggle"
                    onClick={() => dispatch(manageToolOrientation(false))}
                  >
                    <img src={Index.Svg.verticle} />
                  </Index.Box>
                  <Index.Box
                    className="toggle"
                    onClick={() => dispatch(manageToolOrientation(true))}
                  >
                    <img src={Index.Svg.horizontal} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                className="drawing-tool-flex"
                sx={{
                  width: toolOrientation
                    ? `${
                        drawingToolsList
                          ?.filter((ele) => ele?.isActive)
                          ?.filter((_, index) => index % 2 == 0)?.length *
                          50 +
                        10
                      }px`
                    : undefined,
                }}
              >
                {/* <Index.Box className="tool-wrapper"> */}
                <Index.Box className="drop-sec ml-0 thickness-drop tool-thickness-box">
                  <Index.FormControl className="tool-thickness-form-control">
                    <Index.Select
                      defaultValue={strokeThickness}
                      value={strokeThickness}
                      onChange={handleStrokeThicknessChange}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      className="tool-thickness-select"
                    >
                      <Index.MenuItem value={1}>
                        <img
                          className="line-img"
                          src={Index.Svg.lineOne}
                          alt="lineOne"
                        />
                      </Index.MenuItem>
                      <Index.MenuItem value={2}>
                        <img
                          className="line-img"
                          src={Index.Svg.lineTwo}
                          alt="lineTwo"
                        />
                      </Index.MenuItem>
                      <Index.MenuItem value={3}>
                        <img
                          className="line-img"
                          src={Index.Svg.lineThree}
                          alt="lineThree"
                        />
                      </Index.MenuItem>
                      <Index.MenuItem value={4}>
                        <img
                          className="line-img"
                          src={Index.Svg.lineFour}
                          alt="lineFour"
                        />
                      </Index.MenuItem>
                    </Index.Select>
                  </Index.FormControl>
                </Index.Box>
                {/* </Index.Box> */}
                {/* {annotationTypeList?.map((ele) => { */}
                {drawingToolsList &&
                  drawingToolsList?.length > 0 &&
                  drawingToolsList[1]?.isActive && (
                    <Index.Tooltip title={drawingToolsList[1]?.title} arrow>
                      <Index.Box className="tool-wrapper fill-color-tool-wrapper">
                        <Index.Link
                          to=""
                          className="close-bg"
                          onClick={() => handleUpdateTools(2, false)}
                        >
                          <img src={Index.Svg.closeIcon} />
                        </Index.Link>
                        <Index.Box className="model-header-left cus-vertical-center drawing-tool-button">
                          <span
                            className="stroke-color-icon"
                            style={{
                              borderColor: `${strokeColor}`,
                              backgroundColor: `${strokeColor}`,
                            }}
                          ></span>
                          <Index.TextField
                            type="color"
                            className="color-picker"
                            value={strokeColor}
                            onChange={handleStrokeColorChange}
                            hidden
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Tooltip>
                  )}

                {drawingToolsList &&
                  drawingToolsList?.length > 0 &&
                  drawingToolsList
                    ?.slice(2, drawingToolsList?.length)
                    ?.map((ele) => {
                      if (ele?.isActive) {
                        return (
                          <Index.Tooltip key={ele?.id} title={ele?.title} arrow>
                            <Index.Box
                              className={`tool-wrapper ${
                                selectedAnnotationType?.toLocaleLowerCase() ===
                                  ele?.toolName?.toLocaleLowerCase() &&
                                "selected-annotation"
                              }`}
                              // key={ele?.id}
                              onClick={() => {
                                if (ele?.toolName == "remove") {
                                  removeSelectedAnnotation();
                                } else {
                                  addAnnotation(ele?.toolName);
                                }
                              }}
                            >
                              <Index.Link
                                to=""
                                className="close-bg"
                                onClick={() =>
                                  handleUpdateTools(ele?.id, false)
                                }
                              >
                                <img src={Index.Svg.closeIcon} />
                              </Index.Link>
                              <Index.Box className="model-header-left cus-vertical-center drawing-tool-button">
                                <img
                                  className="setting-ic drawing-tool-icon"
                                  alt="settingsolid"
                                  width={13}
                                  // src={ele?.icon}
                                  src={Index.Svg[ele?.icon + theme]}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Tooltip>
                        );
                      }
                    })}
              </Index.Box>
              {drawingToolsList &&
                drawingToolsList?.some((ele) => !ele?.isActive) && (
                  <Index.Box
                    className="drawing-tool-flex remove-zone"
                    sx={{
                      width: toolOrientation
                        ? `${
                            drawingToolsList
                              ?.filter((ele) => !ele?.isActive)
                              ?.filter((_, index) => index % 2 == 0)?.length *
                              50 +
                            30
                          }px`
                        : undefined,
                    }}
                  >
                    {drawingToolsList &&
                      drawingToolsList?.length > 0 &&
                      !drawingToolsList[1]?.isActive && (
                        <Index.Tooltip title={drawingToolsList[1]?.title} arrow>
                          <Index.Box className="tool-wrapper">
                            <Index.Link
                              to=""
                              className="add-bg"
                              onClick={() => handleUpdateTools(2, true)}
                            >
                              <img src={Index.Svg.addIcon} />
                            </Index.Link>
                            <Index.Box className="model-header-left cus-vertical-center drawing-tool-button">
                              <span
                                className="stroke-color-icon"
                                style={{
                                  borderColor: `${strokeColor}`,
                                  backgroundColor: `${strokeColor}`,
                                }}
                              ></span>
                              <Index.TextField
                                type="color"
                                className="color-picker"
                                value={strokeColor}
                                // onChange={handleStrokeColorChange}
                                hidden
                                disabled
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Tooltip>
                      )}

                    {drawingToolsList &&
                      drawingToolsList?.length > 0 &&
                      !drawingToolsList[0]?.isActive && (
                        <Index.Tooltip title={drawingToolsList[0]?.title} arrow>
                          <Index.Box className="tool-wrapper">
                            <Index.Link
                              to=""
                              className="add-bg"
                              onClick={() => handleUpdateTools(1, true)}
                            >
                              <img src={Index.Svg.addIcon} />
                            </Index.Link>
                            <Index.Box className="model-header-left cus-vertical-center drawing-tool-button ">
                              <span
                                className="fill-color-icon"
                                style={{ backgroundColor: `${fillColor}` }}
                              ></span>

                              <Index.TextField
                                type="color"
                                className="color-picker"
                                value={fillColor}
                                onChange={handleFillColorChange}
                                hidden
                                disabled
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Tooltip>
                      )}
                    {drawingToolsList &&
                      drawingToolsList?.length > 0 &&
                      drawingToolsList
                        ?.slice(2, drawingToolsList?.length)
                        ?.map((ele) => {
                          if (!ele?.isActive) {
                            return (
                              <Index.Tooltip
                                key={ele?.id}
                                title={ele?.title}
                                arrow
                              >
                                <Index.Box
                                  className="tool-wrapper"
                                  // key={ele?.id}
                                  // onClick={() => {
                                  //   if (ele?.toolName == "remove") {
                                  //     removeSelectedAnnotation();
                                  //   } else {
                                  //     addAnnotation(ele?.toolName);
                                  //   }
                                  // }}
                                >
                                  <Index.Link
                                    to=""
                                    className="add-bg"
                                    onClick={() =>
                                      handleUpdateTools(ele?.id, true)
                                    }
                                  >
                                    <img src={Index.Svg.addIcon} />
                                  </Index.Link>
                                  <Index.Box className="model-header-left cus-vertical-center drawing-tool-button">
                                    <img
                                      className="setting-ic drawing-tool-icon"
                                      alt="settingsolid"
                                      width={13}
                                      // src={ele?.icon}
                                      src={Index.Svg[ele?.icon + theme]}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Tooltip>
                            );
                          }
                        })}
                  </Index.Box>
                )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default DrawingToolBox;
