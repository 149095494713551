import { useEffect, useRef, useState } from "react";
import Index from "../Index";
import Modal from "../modal/Modal";
import {
  GetGraphTypeAction,
  getAllTagsList,
  manageChartDrawingData,
  manageChartInstance,
  manageDrawingButton,
  manageDrawingToolActiveDeactive,
  manageEraseDrawing,
  manageFlip,
  manageGridColor,
  manageModalParameter,
  manageResetState,
  manageSidebarVisibility,
  manageStopLossTargetAction,
  manageTwoSideArrowZoom,
  manageUserSetting,
  setChartDuration,
  setChartView,
  setTimeFrame,
} from "../../redux/user/action";
import AutoWidthHeight from "../common/AutoWidthHeight";
import moment from "moment";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Slider } from "@mui/material";
import { DataService } from "../../config/DataService";
import useCurrentTime from "../hooks/useCurrentTime";
import Constants from "../common/constants";
// import { KeyShortcut } from "../common/keyboard-shortcuts/KeyBoardShortcuts";

const SideBar = () => {
  const location = useLocation();
  const themeTitle = localStorage.getItem("default-theme");

  const IntradayIntervals = [
    { id: 1, interval: "ONE_MINUTE", value: "1 Minute" },
    { id: 2, interval: "TWO_MINUTE", value: "2 Minute" },
    { id: 3, interval: "THREE_MINUTE", value: "3 Minute" },
    { id: 4, interval: "FOUR_MINUTE", value: "4 Minute" },
    { id: 5, interval: "FIVE_MINUTE", value: "5 Minute" },
    { id: 6, interval: "TEN_MINUTE", value: "10 Minute" },
    { id: 7, interval: "FIFTEEN_MINUTE", value: "15 Minute" },
  ];

  const positionalTimeFrame = [
    {
      timeFrame: "5 Days",
      value: 5,
      time: [
        { intervalTitle: "15 Min", interval: "FIFTEEN_MINUTE" },
        { intervalTitle: "30 Min", interval: "THIRTY_MINUTE" },
        { intervalTitle: "1 Hour", interval: "ONE_HOUR" },
      ],
    },
    {
      timeFrame: "15 Days",
      value: 15,
      time: [
        { intervalTitle: "30 Min", interval: "THIRTY_MINUTE" },
        { intervalTitle: "1 Hour", interval: "ONE_HOUR" },
        { intervalTitle: "4 Hour", interval: "FOUR_HOUR" },
      ],
    },
    {
      timeFrame: "1 Month",
      value: 30,
      time: [
        { intervalTitle: "4 Hour", interval: "FOUR_HOUR" },
        { intervalTitle: "1 Day", interval: "ONE_DAY" },
      ],
    },
    {
      timeFrame: "3 Months",
      value: 90,
      time: [
        { intervalTitle: "4 Hour", interval: "FOUR_HOUR" },
        { intervalTitle: "1 Day", interval: "ONE_DAY" },
      ],
    },
    {
      timeFrame: "6 Months",
      value: 180,
      time: [
        { intervalTitle: "1 Day", interval: "ONE_DAY" },
        { intervalTitle: "1 Week", interval: "ONE_WEEK" },
      ],
    },
    {
      timeFrame: "1 Year",
      value: 365,
      time: [
        { intervalTitle: "1 Day", interval: "ONE_DAY" },
        { intervalTitle: "2 Day", interval: "TWO_DAY" },
        { intervalTitle: "3 Day", interval: "THREE_DAY" },
        { intervalTitle: "1 Week", interval: "ONE_WEEK" },
        { intervalTitle: "2 Week", interval: "TWO_WEEK" },
        { intervalTitle: "3 Week", interval: "THREE_WEEK" },
      ],
    },
  ];
  const chartView = Index.useSelector((state) => state.UserReducer.chartView);
  const {
    displaySettings,
    graphType,
    isDrawingButtonActive,
    selectedChart,
    token: userToken,
    isFlip,
    timeFrame,
    chartDefaultSettings,
    twoSideArrowZoom,
    gridColor,
    theme,
    stopLossTargetAction,
  } = Index.useSelector((state) => state.UserReducer);
  const chartDuration = Index.useSelector(
    (state) => state.UserReducer.chartDuration
  );
  const showSideBar = Index.useSelector(
    (state) => state.UserReducer.showSideBar
  );

  const { height, width } = AutoWidthHeight();
  const [show, setShow] = useState(false);
  const [sorting, setsorting] = useState(false);
  const [gridSettings, setgridSettings] = useState(false);
  const [sidebarChartTypesBtn, setSidebarChartTypesBtn] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [gridSettingsOpacity, setGridSettingsOpacity] = useState(1);
  const [sidebarOpacity, setSidebarOpacity] = useState(1);
  const [time, setTime] = useState(
    moment().format(window.innerWidth < 750 ? "HH:mm" : "HH:mm:ss")
  );
  const [gridModal, setgridModal] = useState(false);
  const position = Index.useSelector((state) => state.UserReducer.position);
  const [gridSetting, setGridSettings] = useState({
    thickness: 1,
    gap: 1,
    color: "#fff",
  });
  const [sortedDataOption, setSortedDataOption] = useState({
    isAsc: true,
    tage: [],
  });
  const userType = Index.useSelector(
    (state) => state.UserReducer.userLoginDetail?.userType
  );

  const sidebarRef = useRef(null);
  const currentTime = useCurrentTime();
  const isTrackingWindowPage = [
    "/user/tracking-window",
    "/user/tracking-window-charts",
  ]?.includes(location?.pathname);

  // const graphTypes = Index.useSelector((state) => state.UserReducer.graphType);

  const mouseOverEvent = () => {
    var element = document.getElementById("sidebar-main");
    element.classList.add("result_hover");
  };
  const mouseLeaveEvent = () => {
    var element = document.getElementById("sidebar-main");
    element.classList.remove("result_hover");
  };
  const sidebarChartTypes = [
    { title: "Line", icon: Index.Svg.lineChart },
    { title: "Area", icon: Index.Svg.areaChart },
    { title: "Dotted", icon: Index.Svg.dottedChart },
    { title: "Candle Stick", icon: Index.Svg.candleStickCharts },
  ];

  const changeChartView = () => {
    let tradingMode = "";
    if (chartView == "Intraday") {
      tradingMode = "Positional";
    } else {
      tradingMode = "Intraday";
    }
    dispatch(setChartView(tradingMode));
    dispatch(manageUserSetting(userToken, "chartView", tradingMode));
  };

  const handleManageTimeFrameInterval = (payload) => {
    dispatch(
      setChartDuration({
        ...chartDuration,
        [chartView]: payload,
      })
    );

    if (chartView === "Intraday") {
      dispatch(manageSidebarVisibility(false));
    }

    dispatch(
      manageUserSetting(userToken, `chartDuration.${chartView}`, payload)
    );
  };

  const handleManageChartType = (payload) => {
    setSidebarChartTypesBtn(payload);
    dispatch(
      GetGraphTypeAction({
        ...graphType,
        [theme]: {
          ...graphType[theme],
          [chartView]: payload,
        },
      })
    );

    dispatch(
      manageUserSetting(userToken, `graphType.${theme}.${chartView}`, payload)
    );
  };

  const dispatch = Index.useDispatch();
  const navigate = useNavigate();

  const handleZoomAndOutContent = (status) => {
    dispatch(manageTwoSideArrowZoom(status));
  };
  const handleFlip = () => {
    dispatch(manageFlip());
    dispatch(manageUserSetting(userToken, "isFlip", !isFlip));
  };

  const handleResetChartDefaultSetting = () => {
    const durationPayload = {
      Intraday: { interval: chartDefaultSettings?.Intraday?.interval || "" },
      Positional: {
        timeFrame: chartDefaultSettings?.Positional?.timeFrame || "",
        interval: chartDefaultSettings?.Positional?.interval || "",
      },
    };
    dispatch(setChartDuration(durationPayload));
    dispatch(manageUserSetting(userToken, "chartDuration", durationPayload));

    const intradayChartType = chartDefaultSettings?.Intraday?.chartType;
    const positionalChartType = chartDefaultSettings?.Positional?.chartType;

    const graphTypePayload = {
      Light: { Intraday: intradayChartType, Positional: positionalChartType },
      Medium: {
        Intraday: intradayChartType,
        Positional: positionalChartType,
      },
      Dark: { Intraday: intradayChartType, Positional: positionalChartType },
    };

    dispatch(GetGraphTypeAction(graphTypePayload));
    dispatch(manageUserSetting(userToken, "graphType", graphTypePayload));
  };

  const handleToggleDrawingTool = () => {
    dispatch(manageDrawingButton());
    dispatch(manageDrawingToolActiveDeactive({ status: true }));
  };

  //Remove all annotation from selected chart
  const getAllAnnotations = async () => {
    try {
      const response = await DataService(userToken).get(
        Index.Api.user.getAnnotations,
        {
          params: {
            chartType: chartView,
            isDashboard: location?.pathname === "/user/dashboard",
            interval:
              Constants.annotationTimeFrames[
                chartDuration[chartView]?.interval
              ],
          },
        }
      );

      dispatch(manageChartDrawingData(response?.data?.data));
    } catch (error) {}
  };
  const handleRemoveAnnotation = async (data) => {
    try {
      const response = await DataService(userToken, "application/json").post(
        Index.Api.user.addUpdateAnnotation,
        {
          ...data,
          isDashboardAnnotations: location?.pathname === "/user/dashboard",
        }
      );
      getAllAnnotations();
    } catch (error) {}
  };

  const removeSelectedChartAnnotation = async () => {
    dispatch(manageEraseDrawing(true));
  };

  const handleTimer = () => {
    setTime(moment().format("HH:mm:ss"));
  };

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     handleTimer();
  //   }, 1000);
  //   return () => clearInterval(timer);
  // }, []);
  useEffect(() => {
    dispatch(manageSidebarVisibility(false));
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        dispatch(manageSidebarVisibility(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const handleElementClick = (e, xAxis, yAxis) => {
    dispatch(
      manageModalParameter({ x: e.clientX - xAxis, y: e.clientY + yAxis })
    );
  };
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTime(moment().format(window.innerWidth < 750 ? "HH:mm" : "HH:mm:ss"));
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    const handleReload = () => {
      if (gridModal || gridSettings || sorting) {
        dispatch(manageModalParameter({}));
      }
    };
    window.addEventListener("beforeunload", handleReload);
    return () => {
      window.removeEventListener("beforeunload", handleReload);
    };
  }, []);

  useEffect(() => {
    if (!gridModal) {
      dispatch(manageGridColor({ type: "previous", color: gridColor }));
      dispatch(manageUserSetting(userToken, "previousGridColor", gridColor));
    }
  }, [gridModal]);

  return (
    <>
      <Index.Box
        id={"sidebar-main"}
        className={`${showSideBar ? "active" : ""} sidebar-main ${
          !twoSideArrowZoom && displaySettings?.UtilityBar == "left"
            ? "sidebar-left"
            : ""
        } custom-sidebar-mob ${twoSideArrowZoom && "hide-sidebar-main"}`}
        sx={{ opacity: sidebarOpacity + 0.2 || 1 }}
        ref={sidebarRef}
      >
        <Index.Box className="sidebar-wraqpper">
          <Index.Box className="siebar-inner-wrapper">
            <Index.Box
              className={`sidebar-top ${
                displaySettings?.UtilityBar == "left" ? "sidebar-top-left" : ""
              }`}
            >
              <Index.List className="main-ul">
                <Index.ListItem
                  className="main-li"
                  onMouseOver={() => {
                    if (!isTrackingWindowPage) {
                      mouseOverEvent();
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isTrackingWindowPage) {
                      mouseLeaveEvent();
                    }
                  }}
                >
                  <Index.Box
                    className="btn-bg hover-dropdown"
                    onClick={changeChartView}
                  >
                    {chartView == "Intraday" ? (
                      <img
                        className="head-btn-img i-img"
                        alt="i"
                        src={Index.Svg.i}
                      />
                    ) : (
                      <Index.Box
                        className="sidebar-pi-btn"
                        style={{ color: "white", fontSize: "22px" }}
                      >
                        P
                      </Index.Box>
                    )}
                  </Index.Box>
                  {chartView == "Intraday" && (
                    <Index.Box
                      className={`li-hover li-hover-positional ${
                        isTrackingWindowPage && "li-hover-none"
                      }`}
                      sx={{ opacity }}
                    >
                      <Index.List className="time-ul">
                        {IntradayIntervals?.map((item) => {
                          return (
                            <Index.ListItem
                              key={item?.id}
                              className={`time-li ${
                                chartDuration[chartView]?.interval ===
                                  item?.interval && "active"
                              }`}
                              onClick={() => {
                                handleManageTimeFrameInterval({
                                  interval: item?.interval,
                                });
                              }}
                            >
                              <Index.Typography
                                className="mint-text"
                                variant="p"
                                component="p"
                              >
                                {item?.value}
                              </Index.Typography>
                            </Index.ListItem>
                          );
                        })}
                      </Index.List>
                    </Index.Box>
                  )}
                  {chartView == "Positional" && (
                    <Index.Box
                      className={`li-hover li-hover-positional ${
                        isTrackingWindowPage && "li-hover-none"
                      }`}
                      sx={{ opacity }}
                    >
                      <Index.List className="time-ul">
                        {positionalTimeFrame?.map((ele) => {
                          return (
                            <Index.ListItem
                              className={`time-li ${
                                chartDuration[chartView]?.timeFrame ===
                                  ele?.value && "active"
                              }`}
                              key={ele?.timeFrame}
                              onClick={() => {
                                const data = {
                                  timeFrame: ele?.value,
                                  interval: ele?.time[0]?.interval,
                                };
                                handleManageTimeFrameInterval(data);
                              }}
                            >
                              <Index.Typography
                                className="mint-text"
                                variant="p"
                                component="p"
                              >
                                {ele?.timeFrame}
                              </Index.Typography>
                            </Index.ListItem>
                          );
                        })}
                      </Index.List>
                    </Index.Box>
                  )}
                </Index.ListItem>
                {chartView == "Positional" && !isTrackingWindowPage && (
                  <Index.ListItem
                    className="main-li"
                    onMouseOver={mouseOverEvent}
                    onMouseLeave={mouseLeaveEvent}
                  >
                    <Index.Box className="btn-bg hover-dropdown">
                      <Index.Box
                        className="sidebar-pi-btn"
                        style={{ color: "white", fontSize: "22px" }}
                      >
                        PI
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      className="li-hover li-hover-positional"
                      sx={{ opacity }}
                    >
                      <Index.List className="time-ul">
                        {positionalTimeFrame
                          ?.find(
                            (ele) =>
                              ele?.value === chartDuration[chartView]?.timeFrame
                          )
                          ?.time?.map((ele) => {
                            return (
                              <Index.ListItem
                                className={`time-li ${
                                  chartDuration[chartView]?.interval ===
                                    ele?.interval && "active"
                                }`}
                                key={ele?.intervalTitle}
                                onClick={() => {
                                  const data = {
                                    ...chartDuration[chartView],
                                    interval: ele?.interval,
                                  };
                                  handleManageTimeFrameInterval(data);
                                }}
                              >
                                <Index.Typography
                                  className="mint-text"
                                  variant="p"
                                  component="p"
                                >
                                  {ele?.intervalTitle}
                                </Index.Typography>
                              </Index.ListItem>
                            );
                          })}
                      </Index.List>
                    </Index.Box>
                  </Index.ListItem>
                )}
                <Index.ListItem
                  className="main-li"
                  onMouseOver={mouseOverEvent}
                  onMouseLeave={mouseLeaveEvent}
                >
                  <Index.Box className="btn-bg hover-dropdown">
                    <img
                      className="head-btn-img"
                      alt="bxlinechart"
                      src={Index.Svg.bxLineChart}
                    />
                  </Index.Box>
                  <Index.Box className="li-hover chart-list" sx={{ opacity }}>
                    <Index.List className="time-ul">
                      {sidebarChartTypes.map((item, index) => (
                        <Index.ListItem
                          className={`time-li ${
                            graphType[theme][chartView] === index && "active"
                          } `}
                          key={index}
                          onClick={() => {
                            handleManageChartType(index);
                          }}
                        >
                          <img
                            className={`chart-ic ${
                              graphType[theme][chartView] != index
                                ? "chart-icon"
                                : "chart-icon-active"
                            } `}
                            alt="linechart"
                            src={item.icon}
                          />
                          <Index.Typography
                            className="mint-text"
                            variant="p"
                            component="p"
                          >
                            {item.title}
                          </Index.Typography>
                        </Index.ListItem>
                      ))}
                    </Index.List>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem
                  className="main-li"
                  onMouseOver={mouseOverEvent}
                  onMouseLeave={mouseLeaveEvent}
                  onClick={handleFlip}
                >
                  <Index.Box
                    className={`btn-bg hover-dropdown ${
                      isFlip && "active-sidebar-btn"
                    } `}
                  >
                    <img
                      className="head-btn-img"
                      alt="flip"
                      src={Index.Svg.flip}
                    />
                  </Index.Box>
                  <Index.Box className="menu-name flip-helper-box">
                    Flip
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem
                  className="main-li"
                  onMouseOver={mouseOverEvent}
                  onMouseLeave={mouseLeaveEvent}
                  onClick={(e) => {
                    if (e.clientY <= 200) {
                      handleElementClick(e, 500, -50);
                    } else {
                      handleElementClick(e, 500, -220);
                    }
                    setsorting((prev) => !prev);
                    dispatch(manageSidebarVisibility(false));
                  }}
                >
                  <Index.Box
                    // className="btn-bg"
                    className={`btn-bg hover-dropdown ${
                      sorting && "active-sidebar-btn"
                    } `}
                  >
                    <img
                      className="head-btn-img"
                      alt="zTOa"
                      src={Index.Svg.zTOa}
                    />
                  </Index.Box>
                  <Index.Box className="menu-name flip-helper-box">
                    Sort
                  </Index.Box>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
            <Index.Box
              className={`sidebar-bottom ${
                displaySettings?.UtilityBar == "left"
                  ? "sidebar-bottom-left"
                  : ""
              }`}
            >
              <Index.Box className="sidebar-second-sec">
                <Index.List className="main-ul">
                  <Index.ListItem
                    className="main-li"
                    onMouseOver={mouseOverEvent}
                    onMouseLeave={mouseLeaveEvent}
                    onClick={(e) => {
                      if (location?.pathname !== "/user/view-single-chart") {
                        if (e.clientY <= 300) {
                          handleElementClick(e, 500, -50);
                        } else {
                          handleElementClick(e, 500, -350);
                        }
                        setgridSettings((prev) => !prev);
                      }
                    }}
                  >
                    <Index.Box
                      // className="btn-bg"
                      className={`btn-bg hover-dropdown ${
                        gridSettings && "active-sidebar-btn"
                      } `}
                    >
                      <img
                        className="head-btn-img"
                        alt="menuList"
                        src={Index.Svg.menuList}
                      />
                    </Index.Box>
                    <Index.Box className="menu-name flip-helper-box">
                      Grid
                    </Index.Box>
                  </Index.ListItem>
                  {!showSideBar && (
                    <Index.ListItem
                      className="main-li"
                      onMouseOver={mouseOverEvent}
                      onMouseLeave={mouseLeaveEvent}
                      onClick={() => handleZoomAndOutContent(!twoSideArrowZoom)}
                    >
                      <Index.Box className="btn-bg hover-dropdown">
                        {/* <img
                          className="head-btn-img"
                          alt="sidebarZoom"
                          src={Index.Svg.sidebarZoom}
                        /> */}
                        <Index.VisibilityOutlinedIcon
                          style={{ fill: "#ffffff", fontSize: 24 }}
                        />
                      </Index.Box>
                      <Index.Box className="menu-name flip-helper-box">
                        Hide / Show
                      </Index.Box>
                      {/* <Index.Box className="menu-name">Full screen</Index.Box> */}
                    </Index.ListItem>
                  )}

                  {location?.pathname === "/user/tracking-window-charts" && (
                    <Index.ListItem
                      className="main-li"
                      onMouseOver={mouseOverEvent}
                      onMouseLeave={mouseLeaveEvent}
                      onClick={() => {
                        dispatch(manageStopLossTargetAction());
                      }}
                    >
                      <Index.Box
                        className={`btn-bg hover-dropdown ${
                          stopLossTargetAction ? "active-sidebar-btn" : ""
                        }`}
                      >
                        <img
                        className="head-btn-img"
                        alt="menuList"
                        src={Index.Svg.tradingIcon}
                      />
                      </Index.Box>
                      <Index.Box className="menu-name flip-helper-box">
                        StopLoss/Target
                      </Index.Box>
                    </Index.ListItem>
                  )}
                  {/* {userType == "admin" ? (
                    <Index.ListItem
                      className="main-li"
                      onMouseOver={mouseOverEvent}
                      onMouseLeave={mouseLeaveEvent}
                      onClick={() => navigate("/user/user-management")}
                    >
                      <Index.Box
                        className={`btn-bg hover-dropdown ${
                          [
                            "/user/user-management",
                            "/user/add",
                            "/user/edit",
                            "/user/users-tagged-scripts",
                          ].includes(location?.pathname) && "active-sidebar-btn"
                        }`}
                      >
                        <img
                          className="head-btn-img"
                          alt="eraser"
                          src={Index.Svg.userManager}
                        />
                      </Index.Box>
                      <Index.Box className="menu-name flip-helper-box">
                        User management
                      </Index.Box>
                    </Index.ListItem>
                  ) : (
                    <></>
                  )}

                  {userType == "admin" ? (
                    <Index.ListItem
                      className="main-li"
                      onMouseOver={mouseOverEvent}
                      onMouseLeave={mouseLeaveEvent}
                      onClick={() => navigate("/user/index-management")}
                    >
                      <Index.Box
                        className={`btn-bg hover-dropdown ${
                          location?.pathname === "/user/index-management" &&
                          "active-sidebar-btn"
                        }`}
                      >
                        <img
                          className="head-btn-img"
                          alt="eraser"
                          src={Index.Svg.indexIcon}
                        />
                      </Index.Box>
                      <Index.Box className="menu-name flip-helper-box">
                        Index management
                      </Index.Box>
                    </Index.ListItem>
                  ) : (
                    <></>
                  )} */}

                  <Index.ListItem
                    className="main-li"
                    onMouseOver={mouseOverEvent}
                    onMouseLeave={mouseLeaveEvent}
                    // onClick={handleEraseAllAnnotation}
                    onClick={removeSelectedChartAnnotation}
                  >
                    <Index.Box className="btn-bg hover-dropdown">
                      <img
                        className="head-btn-img"
                        alt="eraser"
                        src={Index.Svg.earser}
                      />
                    </Index.Box>
                    <Index.Box className="menu-name flip-helper-box">
                      Erase drawing
                    </Index.Box>
                    {/* <Index.Box className="menu-name">Erase drawing</Index.Box> */}
                  </Index.ListItem>
                  <Index.ListItem
                    className="main-li"
                    onMouseOver={mouseOverEvent}
                    onMouseLeave={mouseLeaveEvent}
                    onClick={handleToggleDrawingTool}
                  >
                    <Index.Box
                      className={`btn-bg hover-dropdown ${
                        isDrawingButtonActive && "active-sidebar-btn"
                      } `}
                    >
                      <img
                        className="head-btn-img"
                        alt="toolBox"
                        src={Index.Svg.toolBox}
                      />
                    </Index.Box>
                    <Index.Box className="menu-name flip-helper-box">
                      Drawing tool
                    </Index.Box>
                  </Index.ListItem>
                  <Index.ListItem
                    className="main-li"
                    onMouseOver={mouseOverEvent}
                    onMouseLeave={mouseLeaveEvent}
                    onClick={handleResetChartDefaultSetting}
                  >
                    <Index.Box className="btn-bg hover-dropdown">
                      <img
                        className="head-btn-img reset-ic"
                        alt="reset"
                        src={Index.Svg.reset}
                      />
                    </Index.Box>
                    <Index.Box className="menu-name flip-helper-box">
                      Reset
                    </Index.Box>
                    {/* <Index.Box className="menu-name">Reset</Index.Box> */}
                  </Index.ListItem>
                  {/* <Index.ListItem
                    className="main-li"
                    onClick={() => dispatch(manageDrawingButton())}
                  >
                    <Index.Box
                      className={`btn-bg hover-dropdown ${
                        isDrawingButtonActive && "active-sidebar-btn"
                      } `}
                    >
                      <img
                        className="head-btn-img reset-ic"
                        alt="reset"
                        src={Index.Svg.bxLineChart}
                      />
                    </Index.Box>
                    <Index.Box className="menu-name">Reset</Index.Box>
                  </Index.ListItem> */}
                  <Index.ListItem
                    className="main-li sidebar-time-sec"
                    onMouseOver={mouseOverEvent}
                    onMouseLeave={mouseLeaveEvent}
                  >
                    <Index.Box className="btn-bg time-bg">
                      <Index.Typography
                        className="side-time"
                        variant="p"
                        component="p"
                      >
                        {currentTime}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="menu-name flip-helper-box">
                      Time
                    </Index.Box>
                  </Index.ListItem>
                  <Index.ListItem
                    className="main-li"
                    onMouseOver={mouseOverEvent}
                    onMouseLeave={mouseLeaveEvent}
                  >
                    <Index.Box
                      className="btn-bg opacity-btn sidebar-opacity-btn test"
                      sx={{ minWidth: "35px !important" }}
                    >
                      {/* <p>Opacity</p> */}
                      {/* <Index.Box sx={{ width: 35 }}> */}
                      <Slider
                        aria-label="Temperature"
                        color="secondary"
                        step={25}
                        size="medium"
                        value={sidebarOpacity * 100 + 20 || 100}
                        onChange={(e) => {
                          // setOpacity(e.target.value / 100);
                          setSidebarOpacity(e.target.value / 100);
                        }}
                        // sx={{width:"35px"}}
                      />
                      {/* </Index.Box> */}
                    </Index.Box>
                    <Index.Box className="menu-name flip-helper-box">
                      Opacity
                    </Index.Box>
                  </Index.ListItem>
                </Index.List>
              </Index.Box>
              {/* <Index.Box className="sidebar-time-sec ">
              <Index.Box className="time-bg cus-center">
                <Index.Typography
                  className="side-time"
                  variant="p"
                  component="p"
                >
                  12:39 <br />
                  Am
                </Index.Typography>
              </Index.Box>
            </Index.Box> */}
            </Index.Box>

            {/* <Index.Box
              className="drawer-btn cus-vertical-center"
              onClick={() => setShow(!show)}
            >
              <img
                className="drop-left-ic"
                alt="dropleft"
                src={Index.Svg.dropleft}
              />
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Modal
        show={sorting}
        setShow={setsorting}
        title={"Sorting"}
        x={position?.x}
        y={position?.y}
        opacity={opacity}
        position={position}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        setOpacity={setOpacity}
        width={"407px"}
        html={
          <Index.SortingModal
            setSortedDataOption={setSortedDataOption}
            sortedDataOption={sortedDataOption}
            show={sorting}
            setShow={setsorting}
          />
        }
      />
      <Modal
        show={gridSettings}
        setShow={setgridSettings}
        title={"Grid Settings"}
        position={position}
        x={position?.x}
        y={position?.y}
        opacity={opacity}
        setOpacity={setOpacity}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        width={"448px"}
        html={
          <Index.GridSettings
            setgridSettings={setgridSettings}
            gridSettings={gridSettings}
            gridModal={gridModal}
            setgridModal={setgridModal}
            gridSetting={gridSetting}
            setGridSettings={setGridSettings}
            show={gridSettings}
            handleElementClick={handleElementClick}
            setShow={setgridSettings}
          />
        }
      />
      <Modal
        x={position?.x}
        y={position?.y}
        opacity={gridSettingsOpacity}
        setOpacity={setGridSettingsOpacity}
        position={position}
        show={gridModal}
        setShow={setgridModal}
        title={"Grid Settings"}
        width={"601px"}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        html={
          <Index.GridModal
            gridSetting={gridSetting}
            setGridSettings={setGridSettings}
          />
        }
      />
    </>
  );
};
export default SideBar;
