import list from "../assets/images/svg/list.svg";
import grid from "../assets/images/svg/grid.svg";
import downArrow from "../assets/images/svg/downArrow.svg";
import search from "../assets/images/svg/search.svg";
import chartYellow from "../assets/images/svg/chartYellow.svg";
import chartWhite from "../assets/images/svg/chart-white.svg";
import setting from "../assets/images/svg/setting.svg";
import zoom from "../assets/images/svg/zoom.svg";
import zoomWhite from "../assets/images/svg/zoom-white.svg";
import bar from "../assets/images/svg/bar.svg";
import i from "../assets/images/svg/I.svg";
import bxLineChart from "../assets/images/svg/bxLineChart.svg";
import flip from "../assets/images/svg/flip.svg";
import zTOa from "../assets/images/svg/z-a.svg";
import menuList from "../assets/images/svg/menuList.svg";
import sidebarZoom from "../assets/images/svg/sidebarZoom.svg";
import earser from "../assets/images/svg/earser.svg";
import toolBox from "../assets/images/svg/toolBox.svg";
import reset from "../assets/images/svg/reset.svg";
import lineChart from "../assets/images/svg/lineChart.svg";
import areaChart from "../assets/images/svg/areaChart.svg";
import dottedChart from "../assets/images/svg/dottedChart.svg";
import candleStickCharts from "../assets/images/svg/candleStickCharts.svg";
import downRedArrow from "../assets/images/svg/downRedArrow.svg";
import upGreenArrow from "../assets/images/svg/upGreenArrow.svg";
import download from "../assets/images/svg/download.svg";
import dots from "../assets/images/svg/dots.svg";
import dotsWhite from "../assets/images/svg/dotsWhite.svg";
import settingsSolid from "../assets/images/svg/settings-solid.svg";
import settingsLight from "../assets/images/svg/setting-light.svg";
import settingsDark from "../assets/images/svg/setting-dark.svg";
import minus from "../assets/images/svg/minus.svg";
import close from "../assets/images/svg/close.svg";
import searchWhite from "../assets/images/svg/searchWhite.svg";
import closeWhite from "../assets/images/svg/closeWhite.svg";
import closeDark from "../assets/images/svg/closeDark.svg";
import modalLine from "../assets/images/svg/modalLine.svg";
import modalArea from "../assets/images/svg/modalArea.svg";
import modalDotted from "../assets/images/svg/modalDotted.svg";
import modalCandelChart from "../assets/images/svg/modalCandelChart.svg";
import reload from "../assets/images/svg/reload.svg";
import DefaultLine from "../assets/images/svg/DefaultLine.svg";
import lineOne from "../assets/images/svg/lineOne.svg";
import lineTwo from "../assets/images/svg/lineTwo.svg";
import lineThree from "../assets/images/svg/lineThree.svg";
import lineFour from "../assets/images/svg/lineFour.svg";
import roundPlus from "../assets/images/svg/roundPlus.svg";
import edit from "../assets/images/svg/edit.svg";
import deleteRed from "../assets/images/svg/deleteRed.svg";
import tableChart from "../assets/images/svg/tableChart.svg";
import whiteDots from "../assets/images/svg/whiteDots.svg";
import calender from "../assets/images/svg/calender.svg";
import cross from "../assets/images/svg/cross.svg";
import minusGreen from "../assets/images/svg/minusGreen.svg";
import profilIc from "../assets/images/svg/profilIc.svg";
import dataIc from "../assets/images/svg/dataIc.svg";
import themeIc from "../assets/images/svg/themeIc.svg";
import lightIc from "../assets/images/svg/lightIc.svg";
import mediumIc from "../assets/images/svg/mediumIc.svg";
import darkIc from "../assets/images/svg/darkIc.svg";
import logoutIc from "../assets/images/svg/logoutIc.svg";
import maxScrren from "../assets/images/svg/maxScrren.svg";
import listCheck from "../assets/images/svg/listCheck.svg";
import listChecked from "../assets/images/svg/listChecked.svg";
import dropleft from "../assets/images/svg/drop-left.svg";
import threeDotsWhite from "../assets/images/svg/threeDotsWhite.svg";
import filterIc from "../assets/images/svg/filterIc.svg";
import rightRoundArrow from "../assets/images/svg/rightRoundArrow.svg";
import leftRoundArrow from "../assets/images/svg/leftRoundArrow.svg";
import settingWhiteIc from "../assets/images/svg/settingWhiteIc.svg";
import settingWhite from "../assets/images/svg/settingWhite.svg";
import checkWhite from "../assets/images/svg/checkWhite.svg";
import eyeDrop from "../assets/images/svg/eyeDrop.svg";
import darckCheck from "../assets/images/svg/darckCheck.svg";
import lineChartDown from "../assets/images/svg/lineChartDown.svg";
import areachartDown from "../assets/images/svg/areachartDown.svg";
import dottedchartDown from "../assets/images/svg/dottedchartDown.svg";
import candelchartDown from "../assets/images/svg/candelchartDown.svg";
import gridDark from "../assets/images/svg/gridDark.svg";
import editYellow from "../assets/images/svg/editYellow.svg";
import checkWhitet from "../assets/images/svg/checkWhitet.svg";
import deleteIcon from "../assets/images/svg/delete.svg";
import userManager from "../assets/images/svg/users.svg";
import eyeIcon from "../assets/images/svg/view_icon.svg";
import aToz from "../assets/images/svg/a-z.svg";
import notification from "../assets/images/svg/notification.svg";
import ellipse_toolMedium from "../assets/images/svg/ellipse_tool.svg";
import ellipse_toolLight from "../assets/images/svg/ellipse_tool-light.svg";
import ellipse_toolDark from "../assets/images/svg/ellipse_tool-dark.svg";
import rectangleMedium from "../assets/images/svg/rectangle.svg";
import rectangleLight from "../assets/images/svg/rectangle-light.svg";
import rectangleDark from "../assets/images/svg/rectangle-dark.svg";
import triangleMedium from "../assets/images/svg/triangle.svg";
import triangleLight from "../assets/images/svg/triangle-light.svg";
import triangleDark from "../assets/images/svg/triangle-dark.svg";
import horizontalLineMedium from "../assets/images/svg/horizontal-line.svg";
import horizontalLineLight from "../assets/images/svg/horizontal-line-light.svg";
import horizontalLineDark from "../assets/images/svg/horizontal-line-dark.svg";
import verticalLineMedium from "../assets/images/svg/vertical-line.svg";
import verticalLineLight from "../assets/images/svg/vertical-line-light.svg";
import verticalLineDark from "../assets/images/svg/vertical-line-dark.svg";
import lineSegmentMedium from "../assets/images/svg/line-segment.svg";
import lineSegmentLight from "../assets/images/svg/line-segment-light.svg";
import lineSegmentDark from "../assets/images/svg/line-segment-dark.svg";
import fibonacciArcMedium from "../assets/images/svg/fibonacciArc.svg";
import fibonacciArcLight from "../assets/images/svg/fibonacciArc-light.svg";
import fibonacciArcDark from "../assets/images/svg/fibonacciArc-dark.svg";
import trendChannelMedium from "../assets/images/svg/trendChannel.svg";
import trendChannelLight from "../assets/images/svg/trendChannel-light.svg";
import trendChannelDark from "../assets/images/svg/trendChannel-dark.svg";
import markerMedium from "../assets/images/svg/marker.svg";
import markerLight from "../assets/images/svg/marker-light.svg";
import markerDark from "../assets/images/svg/marker-dark.svg";
import removeSelectedMedium from "../assets/images/svg/remove-tool.svg";
import removeSelectedLight from "../assets/images/svg/remove-tool-light.svg";
import removeSelectedDark from "../assets/images/svg/remove-tool-dark.svg";
import andrewsPitchforkMedium from "../assets/images/svg/andrews-pitchfork.svg";
import andrewsPitchforkLight from "../assets/images/svg/andrews-pitchfork-light.svg";
import andrewsPitchforkDark from "../assets/images/svg/andrews-pitchfork-dark.svg";
import fibonacciFanMedium from "../assets/images/svg/fibonacci-fan.svg";
import fibonacciFanLight from "../assets/images/svg/fibonacci-fan-light.svg";
import fibonacciFanDark from "../assets/images/svg/fibonacci-fan-dark.svg";
import fibonacciRetracementMedium from "../assets/images/svg/fibonacci-retracement.svg";
import fibonacciRetracementLight from "../assets/images/svg/fibonacci-retracement-light.svg";
import fibonacciRetracementDark from "../assets/images/svg/fibonacci-retracement-dark.svg";
import fibonacciTimeZoneMedium from "../assets/images/svg/fibonacci-time-zone.svg";
import fibonacciTimeZoneLight from "../assets/images/svg/fibonacci-time-zone-light.svg";
import fibonacciTimeZoneDark from "../assets/images/svg/fibonacci-time-zone-dark.svg";
import infiniteLineMedium from "../assets/images/svg/infinite-line.svg";
import infiniteLineLight from "../assets/images/svg/infinite-line-light.svg";
import infiniteLineDark from "../assets/images/svg/infinite-line-dark.svg";
import rayMedium from "../assets/images/svg/ray.svg";
import rayLight from "../assets/images/svg/ray-light.svg";
import rayDark from "../assets/images/svg/ray-dark.svg";
import favorite from "../assets/images/svg/favorite.svg";
import verticle from "../assets/images/svg/verticle.svg";
import horizontal from "../assets/images/svg/horizontal.svg";
import closeIcon from "../assets/images/svg/close-icon.svg";
import addIcon from "../assets/images/svg/add-icon.svg";
import chartNewIcon from "../assets/images/svg/chart-new-icon.svg";
import indexIcon from "../assets/images/svg/index-icon.svg";
import roundPlusDark from "../assets/images/svg/roundPlusDark.svg";
import lightChecked from "../assets/images/svg/light-checked.svg";
import tradingIcon from "../assets/images/svg/trading-icon.svg";

const Svg = {
  lightChecked,
  verticle,
  horizontal,
  closeIcon,
  addIcon,
  chartNewIcon,
  listCheck,
  listChecked,
  userManager,
  deleteIcon,
  eyeIcon,
  profilIc,
  logoutIc,
  aToz,
  mediumIc,
  darkIc,
  lightIc,
  themeIc,
  dataIc,
  edit,
  list,
  grid,
  downArrow,
  search,
  chartYellow,
  setting,
  zoom,
  bar,
  i,
  bxLineChart,
  flip,
  zTOa,
  menuList,
  sidebarZoom,
  earser,
  toolBox,
  reset,
  lineChart,
  areaChart,
  dottedChart,
  candleStickCharts,
  downRedArrow,
  download,
  dots,
  dotsWhite,
  settingsSolid,
  minus,
  close,
  searchWhite,
  closeWhite,
  closeDark,
  modalLine,
  modalArea,
  modalDotted,
  modalCandelChart,
  reload,
  DefaultLine,
  lineOne,
  lineTwo,
  lineThree,
  lineFour,
  roundPlus,
  deleteRed,
  tableChart,
  whiteDots,
  calender,
  cross,
  minusGreen,
  maxScrren,
  dropleft,
  threeDotsWhite,
  filterIc,
  rightRoundArrow,
  leftRoundArrow,
  settingWhiteIc,
  checkWhite,
  eyeDrop,
  darckCheck,
  lineChartDown,
  areachartDown,
  dottedchartDown,
  candelchartDown,
  gridDark,
  editYellow,
  checkWhitet,
  upGreenArrow,
  notification,
  ellipse_toolMedium,
  ellipse_toolLight,
  ellipse_toolDark,
  triangleMedium,
  triangleLight,
  triangleDark,
  rectangleMedium,
  rectangleLight,
  rectangleDark,
  horizontalLineMedium,
  horizontalLineLight,
  horizontalLineDark,
  verticalLineMedium,
  verticalLineLight,
  verticalLineDark,
  lineSegmentMedium,
  lineSegmentLight,
  lineSegmentDark,
  fibonacciArcMedium,
  fibonacciArcLight,
  fibonacciArcDark,
  trendChannelMedium,
  trendChannelLight,
  trendChannelDark,
  markerMedium,
  markerLight,
  markerDark,
  removeSelectedMedium,
  removeSelectedLight,
  removeSelectedDark,
  rayMedium,
  rayLight,
  rayDark,
  infiniteLineMedium,
  infiniteLineLight,
  infiniteLineDark,
  fibonacciTimeZoneMedium,
  fibonacciTimeZoneLight,
  fibonacciTimeZoneDark,
  fibonacciRetracementMedium,
  fibonacciRetracementLight,
  fibonacciRetracementDark,
  fibonacciFanMedium,
  fibonacciFanLight,
  fibonacciFanDark,
  andrewsPitchforkMedium,
  andrewsPitchforkLight,
  andrewsPitchforkDark,
  favorite,
  settingWhite,
  zoomWhite,
  chartWhite,
  indexIcon,
  roundPlusDark,
  settingsLight,
  settingsDark,
  tradingIcon,
};

export default Svg;
